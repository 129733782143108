import React, { useState, useEffect } from 'react';
import ReportPDF from '../report/ReportPDF';

//Importación de contextos
import { useContext } from 'react';
import { CRMContext } from '../providers/CRMProvider';
import { SettingsContext } from '../providers/SettingsProvider';
import { ClientContext } from '../providers/ClientProvider';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';

//Importación elementos gráficos
import './stepper.css'
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Grow, TextField } from '@mui/material';

const StepperContainer = () => {

  //Variables de estado de stepper para manipular Adelante y atrás en el stepper
  const [step, setStep] = useState(1);
  const [pdfDocument, setPdfDocument] = useState(null);
  //Variables de estado donde se guardarán el texto del comentario opcional y los valores de los checkbox elegidos
  const [textInput, setTextInput] = useState('');

  //Input Personalizado para una sugerencia
  const [textoIngresado, setTextoIngresado] = useState('');

  // Función para manejar cambios en el input
  const handleInputChange = (event) => {
    setTextoIngresado(event.target.value);
  };

  //Variable de estado que guarda la captura
  const { capturedCanvas, clientsData, selectedClient } = useContext(CRMContext);
  const { usuarioAsesor, firma } = useContext(ClientContext)

  //Variable de estado para los valores predeterminados
  const { selectedActionsDone, setSelectedActionsDone, selectedActionsToTake, setSelectedActionsToTake, actionsDone, actionsToTake, dateReport, introduccionData, analisisMercadoData, executeFunction } = useContext(SettingsContext)

  // Variable de estado para controlar la visibilidad del mensaje de carga
  const [isLoading, setIsLoading] = useState(true);

  // Temporizador para la carga de la vista previa
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  //Manejadores de eventos: hacia adelante, hacia atrás
  const handleNext = () => {

    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  //Función para el guardado de los checkbox correspondientes a las acciones realizadas:
  const handleCheckboxChange = (event) => {
    const selectedAction = event.target.value;
    if (event.target.checked) {
      setSelectedActionsDone([...selectedActionsDone, selectedAction]);
      return
    } else {
      const updatedActions = selectedActionsDone.filter((action) => action !== selectedAction);
      setSelectedActionsDone(updatedActions);
      return
    }
  };

  //Función para el guardado de los checkbox correspondientes a las acciones a realizar
  const handleCheckboxChangeToTake = (event) => {
    const selectedAction = event.target.value;
    if (event.target.checked) {
      setSelectedActionsToTake([...selectedActionsToTake, selectedAction]);
      return
    } else {
      const updatedActions = selectedActionsToTake.filter((action) => action !== selectedAction);
      setSelectedActionsToTake(updatedActions);
      return
    }
  };

  useEffect(() => {
    if (pdfDocument) {
      // Lógica nuevo estado
      console.log('Documento PDF listo:', pdfDocument);
    };

  }, [setSelectedActionsToTake, setSelectedActionsDone, setTextInput, pdfDocument]);

  return (
    <div className='step-container'>
      {step === 1 && (
        <Grow in={true}>
          <div>
            <h2>1- Ingresá un comentario en el reporte</h2>
            <p>Podés ingresar un comentario sobre las métricas arrojadas sobre la propiedad de tu cliente. Este comentario formará parte del reporte y te permite explicar mejor la lectura que hiciste sobre la propiedad.</p>
            <div className='form'>
              <TextField
                label="Ingrese el comentario"
                variant="outlined"
                fullWidth
                multiline
                rows={10}
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
              />
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleNext}
            >
              <ArrowForwardIcon /> Siguiente
            </Button>
          </div>
        </Grow>
      )}
      {step === 2 && (
        <Grow in={true}>
          <div>
            <h2>2- Seleccioná las acciones realizadas durante este período</h2>
            <p>Tildá la casilla de cada una de las acciones realizadas que consideres necesaria. Las mismas se incorporarán en el informe que le enviarás a tu cliente.</p>
            <div className='form'>
              <FormGroup>
                {actionsDone.map((action, index) => (
                  <FormControlLabel
                    key={index}
                    control={<Checkbox color="success" onChange={handleCheckboxChange} value={action} />}
                    label={action}
                  />
                ))}
              </FormGroup>
            </div>
            <div className='buttons-pdf'>
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePrev}
              >
                <ArrowBackIcon /> Anterior
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
              >
                <ArrowForwardIcon /> Siguiente
              </Button>
            </div>
          </div>
        </Grow>
      )}
      {step === 3 && (
        <Grow in={true}>
          <div>
            <h2>3- Seleccioná las acciones a ejecutar</h2>
            <p>Debés seleccionar las opciones que creas conveniente teniendo en cuenta lo visto en el reporte. Las mismas son acciones a futuro, es decir, en base al análisis, aquellas cuestiones que le ofrecerás a tu cliente para mejorar la posición de su propiedad</p>
            <div className='form'>
              <FormGroup>
                {actionsToTake.map((action, index) => (
                  <FormControlLabel
                    key={index}
                    control={<Checkbox color="success" onChange={handleCheckboxChangeToTake} value={action} />}
                    label={action}
                  />
                ))}
              </FormGroup>

              <div>
                <TextField
                  label="Ingresa tu acción a ejecutar personalizada"
                  variant="standard"
                  value={textoIngresado}
                  onChange={handleInputChange}
                  sx={{ width: '400px', '& input': { fontSize: '0.8rem' } }} 
                />
              </div>

            </div>
            <div className='buttons-pdf'>
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePrev}
              >
                <ArrowBackIcon /> Anterior
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
              >
                <ArrowForwardIcon /> Siguiente
              </Button>
            </div>
          </div>
        </Grow>
      )}
      {step === 4 && (
        <Grow in={true}>
          <div className='pdf-container'>
            <h2>2- Vista previa del reporte</h2>
            <div className='pdf-viewer'>
              {isLoading ? (<div style={{ width: 800, height: 350 }}><CircularProgress color="success" /></div>) : (<PDFViewer width='800' height="350">
                <ReportPDF capturedCanvas={capturedCanvas} introduccionData={introduccionData} comment={textInput} selectedActionsDone={selectedActionsDone} analisisMercadoData={analisisMercadoData} selectedActionsToTake={selectedActionsToTake} customAction={textoIngresado} clientsData={clientsData} selectedClient={selectedClient} dateReport={dateReport} usuarioAsesor={usuarioAsesor} firma={firma} />
              </PDFViewer>)}

            </div>
            <div className='buttons-pdf'>
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePrev}
              >
                <ArrowBackIcon /> Anterior
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
              >
                <ArrowForwardIcon /> Siguiente
              </Button>
            </div>
          </div>
        </Grow>
      )}
      {step === 5 && (
        <Grow in={true}>
          <div>
            <h2>Paso 3</h2>
            <p className='paso-3-container'>El reporte para tu cliente ha sido generado. Podes descargarlo o enviarlo directamente a tu cliente.</p>
            <div className='paso-3-button-container'>
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePrev}
              >
                <ArrowBackIcon /> Anterior
              </Button>
              <PDFDownloadLink
                document={<ReportPDF capturedCanvas={capturedCanvas} introduccionData={introduccionData} comment={textInput} selectedActionsDone={selectedActionsDone} analisisMercadoData={analisisMercadoData} selectedActionsToTake={selectedActionsToTake} customAction={textoIngresado} clientsData={clientsData} selectedClient={selectedClient} dateReport={dateReport} usuarioAsesor={usuarioAsesor} firma={firma} />}
                fileName='reporte.pdf'
              >
                {({ blob, url, loading, error }) => {
                  if (!loading && blob) {
                    setPdfDocument(blob); // Esta línea guardará el PDF una vez está disponible
                  }

                  return (
                    <Button variant='contained' color='secondary' disabled={loading}>
                      <ArrowDownwardIcon /> {loading ? 'Descargando...' : 'Descargar'}
                    </Button>
                  );
                }}
              </PDFDownloadLink>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => { executeFunction(usuarioAsesor, clientsData, selectedClient, pdfDocument) }}
              >
                <SendIcon /> Enviar
              </Button>
            </div>
          </div>
        </Grow>
      )}
    </div>
  );
};

export default StepperContainer;
