import React, { createContext, useContext, useEffect, useState } from 'react'
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';

//Creación de contexto
const ClientContext = createContext();

const ClientProvider = ({ children }) => {

    //Variables de estado:
    const [clientsData, setClientsData] = useState([]);

    //Hook para crear contexto
    const user = useContext(UserContext);

    //Variable que guarda el asesor para consulta en firestore
    const usuarioAsesor = user?.nombre

    //Variable para guardar la url de la firma a utilizar en el reporte
    const firma = user?.firma

    //Fetch para taer clientes desde la API
    useEffect(() => {
        if (user?.codigo) {
            //Fecha actual
            const fecha = new Date();
            // Obtener día, mes año y pasar a una variable
            const dia = fecha.getDate();
            const mes = fecha.getMonth() + 1;
            const anio = fecha.getUTCFullYear();
            const fechaFormateada = `${dia}/${mes}/${anio}`
            const fetchData = async () => {
                try {
                    const params = new URLSearchParams({
                        id: process.env.REACT_APP_API_ID,
                        token: process.env.REACT_APP_API_TOKEN,
                        idcliente: process.env.REACT_APP_API_ID,
                        fecha_desde: "01/01/2012",
                        fecha_hasta: fechaFormateada,
                        usuario: user?.codigo,
                        carpeta: 3
                    });

                    const response = await fetch(`${process.env.REACT_APP_API_URL}?${params}`);
                    const jsonData = await response.json();
                    setClientsData(jsonData);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData();
        };

    }, [user]);
    // Manejo de caso donde el usuario aún no está disponible, por cualquier razón.
    if (!user?.codigo) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress color="success" />
            </div>
        )
    };

    //Servir las variables a los commponente que consumen el contexto
    return (
        <ClientContext.Provider value={{ clientsData, usuarioAsesor, firma }}>
            {children}
        </ClientContext.Provider>
    );
};

export { ClientProvider, ClientContext };