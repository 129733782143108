import { Box, Divider, Grow } from '@mui/material'
import React from 'react'

const Faq = () => {
    return (
        <Grow in={true}>
            <Box m='30px'>
                <Divider />
                <h1>Preguntas frecuentes (FAQ)</h1>
                <h3>En esta sección encontrarás algunas consideraciones sobre el funcionamiento de la aplicación. Si tenés algún inconveniente en el uso, repasá los siguientes puntos para conocer más sobre el problema</h3>
                <Divider style={{ padding: '20px' }} />
                <h2>1- ¿Cómo visualizar las métricas que se enviarán en el informe?</h2>
                <p>La aplicación permite generar un reporte de métricas sobre determinadas propiedades que el cliente posee y que son gestionadas por Prisma. Para ello, al ingresar a "Métricas", el usuario debe elegir el cliente en cuestión , y luego, de existir, se mostrarán en el selector, las propiedades que se encuentran activas. Tras seleccionar, se mostrarán en pantalla las métricas correspondientes al último peíodo completo disponible. Sin embargo, el usuario podrá seleccionar el rango de tiempo que desee mostrar en los gráficos. Si no realiza esta operación, por defecto solo se mostrará el último período.</p>
                <Divider />
                <h2>2- ¿Cómo generar un reporte?</h2>
                <p>Luego de obtener las métricas y que estas se muestren en pantalla, deberás tocar el botón "Enviar Reporte". Se dirigirá a una ventana distinta donde deberás introducir un breve comentario sobre el informe (opcional), y seguir los pasos. En el paso 2 podrás obtener una vista previa del informe, y en caso de querer modificar el comentario, podrás retroceder un paso. Luego, el paso 3 te permitirá descargar el documento pdf o enviarlo a tu cliente.</p>
                <Divider />
                <h2>3- ¿Qué sucede si no se cargan los datos de la APP?</h2>
                <p>Puede deberse a una caída temporal del servidor de datos. Probá con actualizar o volver a loguearte a la APP.</p>
                <Divider />
                <h2>4- ¿Puedo utilizar la App desde el móvil?</h2>
                <p>La aplicación puede utilizarse desde otros dispositivos, pero en la actualidad está optimizada para visualizarse en computadoras, o pantallas medianas y grandes.</p>
                <Divider />
                <h2>5- Compatibilidad con navegadores</h2>
                <p>La APP es compatible con navegadores Safari, Chrome y Firefox. Está optimizada para ser utilizada en pantallas de 1920x1080 de resolución.</p>
            </Box>
        </Grow>
    )
}

export default Faq