import React, { useContext } from 'react'
import { SettingsContext } from '../providers/SettingsProvider';

//Importación hoja de estilos CSS
import './settings.css'

//Importación componentes de MUI (elementos gráficos)
import Button from '@mui/material/Button';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { TextField } from '@mui/material';
import Swal from 'sweetalert2';


const Settings = () => {

  const { actualizarIntroduccionData, actualizarAnalisisMercado, setIntroduccionData, introduccionData, analisisMercadoData, setAnalisisMercadoData, whatsAppMessage, setWhatsAppMessage, actualizarWhatsAppMessage } = useContext(SettingsContext);

  const handleTexIntroductionChange = (event) => {
    setIntroduccionData(event.target.value);
  };

  const handleTexAnalisisMercadoChange = (event) => {
    setAnalisisMercadoData(event.target.value);
  };

  const handleWhatsAppMessajeChange = (event) => {
    setWhatsAppMessage(event.target.value)
  }

  const handleSaveMessage1 = () => {
    // Llama a la función para guardar los cambios en la introducción
    const newData = { mensaje: introduccionData }
    actualizarIntroduccionData(newData);
    Swal.fire({
      text: "¡Cambios guardados!",
      icon: "success"
    });
  };

  const handleSaveMessage2 = () => {
    // Verifica si la propiedad 'mensaje' ya existe en analisisMercadoData
    if (analisisMercadoData.hasOwnProperty('mensaje')) {
      // Si existe, actualiza su valor
      const newData = { mensaje: analisisMercadoData.mensaje };
      actualizarAnalisisMercado(newData);
      Swal.fire({
        text: "¡Mensaje actualizado!",
        icon: "success"
      });
    } else {
      // Si no existe, crea la propiedad 'mensaje' y guarda su valor
      const newData = { mensaje: analisisMercadoData };
      Swal.fire({
        text: "¡Cambios guardados!",
        icon: "success"
      });
      actualizarAnalisisMercado(newData);
    }
  };

  const handleSaveWhatsAppMessage = () => {
    // Verifica si la propiedad 'mensaje' ya existe en analisisMercadoData
    if (whatsAppMessage.hasOwnProperty('mensaje')) {
      // Si existe, actualiza su valor
      const newData = { mensaje: whatsAppMessage.mensaje };
      actualizarWhatsAppMessage(newData);
      Swal.fire({
        text: "¡Mensaje de WhatsApp actualizado!",
        icon: "success"
      });
    } else {
      // Si no existe, crea la propiedad 'mensaje' y guarda su valor
      const newData = { mensaje: whatsAppMessage };
      actualizarWhatsAppMessage(newData);
      Swal.fire({
        text: "¡Mensaje de WhatsApp guardado",
        icon: "success"
      });
    }
  };

  return (
    <div className='settings-container'>
      <div className='text-center'>
        <h2>Configuración del sistema de mensajes estáticos</h2>
        <p>En esta sección podrás modificar y guardar los valores de los mensajes estáticos que estarán por default en los reportes que envíes a tus clientes. Tené en cuenta que cada usuario que modifique los datos, dejará el mensaje predefinido para el resto de los usuarios del sistema.</p>
      </div>
      <div>
        <h3>Mensaje de saludo en el inicio del reporte</h3>
        <p>En este campo, modificá el mensaje de bienvenida que irá en el encabezado del reporte que le enviarás a tu cliente. Luego de revisar los cambios, presioná "guardar" para que el mensaje quede preseteado en la app. El mismo no se modificará salvo que vuelvas a ingresar a este apartado y lo cambies. Tené el cuenta que el nombre del propietario al cual va dirigido se modifica de manera autompatica al elegir un cliente.</p>
        <div className='form'>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={8}
            value={introduccionData?.mensaje}
            onChange={handleTexIntroductionChange}
          />
          <div className='buttons-pdf'>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSaveMessage1}
            >
              <SaveOutlinedIcon /> Guardar
            </Button>

          </div>
        </div>
        <div>
          <h3>Texto para análisis de mercado</h3>
          <p>Este es el texto donde podrás editar el análisis de mercado, que será igual para todos los clientes.</p>
          <div className='form'>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={17}
              value={analisisMercadoData?.mensaje}
              onChange={handleTexAnalisisMercadoChange}
            />
            <div className='buttons-pdf'>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSaveMessage2}
              >
                <SaveOutlinedIcon /> Guardar
              </Button>
            </div>
          </div>
        </div>
        <div>
          <h3>Mensaje de WhatsApp</h3>
          <p>Configura el mensaje central que le enviaremos a tu cliente, que lleva adjunto el reporte. El saludo que se puede editar aquí es el central. El "hola" y nombre del cliente están seteados por default.</p>
          <div className='form'>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={8}
              value={whatsAppMessage?.mensaje}
              onChange={handleWhatsAppMessajeChange}
            />
            <div className='buttons-pdf'>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSaveWhatsAppMessage}
              >
                <SaveOutlinedIcon /> Guardar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Settings