import React, { useContext } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from "react-chartjs-2"
import { CRMContext } from '../providers/CRMProvider';
import { useTheme } from '@mui/material';
import { themeSettings } from '../../theme.js'

//Registro del gráfico API
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {

  const theme = useTheme(); //Contexto de tema de color
  const { variableCRMMensual } = useContext(CRMContext); //Variable de datos de contexto traída desde el CRM

  //Guardado de datos en variables para confección del chart
  const scores = variableCRMMensual?.content?.portales ? Object.values(variableCRMMensual.content.portales) : [];
  const etiquetas = variableCRMMensual?.content?.portales ? Object.keys(variableCRMMensual.content.portales) : []

  // TOUPPERCASE para la primera letra de cada etiqueta
  const labels = etiquetas.map(etiqueta => {
    // Verificar si la etiqueta no está vacía
    if (etiqueta) {
      // Obtener la primera letra y convertirla a mayúscula
      const firstLetter = etiqueta.charAt(0).toUpperCase();

      // Concatenar la primera letra en mayúscula con el resto de la palabra
      const capitalizedLabel = firstLetter + etiqueta.slice(1);

      return capitalizedLabel;
    } else {
      return etiqueta; // Si la etiqueta está vacía, mantenerla igual
    }
  });

  //Personaliazación de gráfico
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Distribución de consultas mensuales (último mes)',
        font: {
          size: 17,
          family: themeSettings(theme.palette.mode).typography.fontFamily,
          weight: 'bold',
        },
        color: 'black'
        // color: colors.grey[200],
      },
      legend: {
        display: true,
        labels: {
          font: {
            size: 14,
            family: theme.typography.fontFamily,
          },
          color: 'black'
          // color: colors.grey[200],
        },
        position: 'right',
        align: 'center',
        fullWidth: true,
      },

    },

  };

  const data = {

    datasets: [
      {
        data: scores,
        backgroundColor: [
          'rgb(53, 21, 93)',
          'rgb(81, 43, 129)',
          'rgb(68, 119, 206)',
          'rgb(140, 171, 255)',
          'rgb(99, 41, 33)',
          'rgb(68, 98, 123)'
        ],
        borderWidth: 0.5,
        pointRadius: 1,
        hoverOffset: 1
      },
    ],
    labels
  };

  return (
    <Pie data={data} options={options} />
  )
};

export default PieChart