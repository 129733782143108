import React from 'react'
import TableCont from '../table/TableCont'
import { Grow } from '@mui/material'

//Importación de contextos de data y user
import { useContext } from 'react';
import { ClientContext } from '../providers/ClientProvider';

const Dashboard = () => {

  const { clientsData } = useContext(ClientContext)

  return (
    <Grow in={true}>
      <div>
        <div className='dashboardContainer'>
          <div className='headContainer'>
            <div className='titleDashboardContainer'>
              <h2>Listado de clientes</h2>
            </div>
            <div className='searchContainer'>
            </div>
          </div>
          <div className='tableContainer'>
            <TableCont clients={clientsData} />
          </div>
        </div>
      </div>
    </Grow>
  )
};

export default Dashboard