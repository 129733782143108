import * as React from 'react';
import ClientList from './ClientList';

//Importación hoja de estilo css
import '../dashboard/dashboard.css'


const TableCont = ({clients}) => {

  const clientsDatabase = clients

  return (
    <div>
      <ClientList
        clientes={clientsDatabase} />
    </div>
  );
}

export default TableCont;
