import React, { useContext } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, Filler, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTheme } from '@mui/material';
import { themeSettings } from '../../theme';
import { CRMContext } from '../providers/CRMProvider';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend, Filler);

const EventsChart = () => {

  const theme = useTheme();
 //Uso de contexto variable de CRM
  const { variableCRMMensual } = useContext(CRMContext)

  const scores = variableCRMMensual?.content?.eventos ? Object.values(variableCRMMensual.content.eventos) : [];
  const etiquetas = variableCRMMensual?.content?.eventos ? Object.keys(variableCRMMensual.content.eventos) : []

  // TOUPPERCASE: función para mapeo de etiquetas y agregado de la mayúscula al principio de cada palabra
  const labels = etiquetas.map(etiqueta => {
    // Verificar si la etiqueta no está vacía
    if (etiqueta) {
      // Obtener la primera letra y convertirla a mayúscula
      const firstLetter = etiqueta.charAt(0).toUpperCase();
      // Concatenar la primera letra en mayúscula con el resto de la palabra
      const capitalizedLabel = firstLetter + etiqueta.slice(1);
      return capitalizedLabel;
    } else {
      return etiqueta; // Si la etiqueta está vacía, mantenerla igual!
    }
  });

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Conversiones',
        font: {
          size: 18,
          family: themeSettings(theme.palette.mode).typography.fontFamily,
          weight: 'bold',
        },
        color: 'black'
        // color: colors.grey[200],
      },
      legend: {
        display: false,
        position: 'top',
        align: 'center',
        labels: {
          font: {
            size: 14,
            family: themeSettings(theme.palette.mode).typography.fontFamily,
            weight: 'bold',
          },
          color: 'black'
          // color: colors.grey[200],
        }
      },

    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 13,
          },
          color: 'black'
          // color: colors.grey[200],
        },
      },
      y: {
        ticks: {
          font: {
            size: 13,
          },
          color: 'black'
          // color: colors.grey[200],
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: scores,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: [
          'rgb(27, 2, 87)',
          'rgb(56, 102, 242)',
          'rgb(5, 151, 242)'],
      },
    ],
  };

  return (
    <Bar options={options} data={data} />
  )
};

export default EventsChart