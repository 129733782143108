import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { TextField, Button, Container, Typography } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import 'react-toastify/dist/ReactToastify.css';
import './loguin.css';
import logo from '../../assets/images/logogreentransparente.png';

const Login = ({ onSuccessfulLogin }) => {

  //Hooks de almacenamiento de usuario y constraseña 
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //Configuración de Toastify
  const errorMessage = (message) => {
    toast.error('Error durante el inicio de sesión: ' + message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  };

  // Manejadores de logueo: click y key
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  //Llamada a auth de Firebase y guardado en variable
  const handleLogin = () => {
    const auth = getAuth();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        //Notificación promise pending...
        toast.loading("Iniciando sesión...");
        //Setimeout para dar tiempo a la notificación en proceso...
        setTimeout(() => {
          console.log("Inicio de sesión exitoso");
          // Llamado a la función para actualizar el estado isLoggedIn en App
          onSuccessfulLogin();
        }, 3000)

      })
      .catch((error) => {
        const errorCode = error.code;
        //Llamada de la función notificación Toastify
        errorMessage(errorCode);
      });
  };

  //Seteo el Hook de usuario y contraseña
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="login-container">
      <Container align="center" maxWidth="sm">
        <img className="imgLogo" src={logo} alt="logo-prisma" />
        <Container maxWidth="sm">
          <div className="login-form form-container">
            <Typography variant="h6" style={{ color: 'white' }} align="center" gutterBottom>
              ¡Hola! Iniciá sesión en la APP
            </Typography>
            <form>
              <TextField
                label="Usuario"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                size="small"
                inputProps={{ style: { color: 'white' } }}
                color="success" focused
                onChange={handleEmailChange}
                onKeyPress={handleKeyPress}
              />
              <TextField
                label="Contraseña"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={password}
                size="small"
                inputProps={{ style: { color: 'white' } }}
                color="success" focused
                onChange={handlePasswordChange}
                onKeyPress={handleKeyPress}
              />
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                style={{ color: 'white', marginTop: '16px' }}
                onClick={handleLogin}
              >
                Login
              </Button>
            </form>
          </div>
        </Container>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default Login;

