import { useState } from "react";
import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

//Elementos gráficos y hoja de estilos
import "react-pro-sidebar/dist/css/styles.css";
import logoBlue from '../../../assets/images/logobluetransparente.png'
import logo from '../../../assets/images/logolightbluetransparente.png'
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  // Obtener el valor almacenado en localStorage
  const authenticatedUserUID = localStorage.getItem("authenticatedUserUID");

  // Verificar si el valor coincide con la cadena deseada
  const isAccessAllowed = authenticatedUserUID === "GTcGheEijFfzHHrAXmE057nnyJ72";

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#3bd74f !important",
        },
        "& .pro-menu-item.active": {
          color: "#AE9D8E !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h7" color={colors.grey[100]}>
                  APP Panel
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                {theme.palette.mode === "dark" ? (
                  <img
                    alt="profile-user"
                    width="130px"
                    // height="100px"
                    src={logo}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                  />
                ) : (
                  <img
                    alt="profile-user"
                    width="130px"
                    // height="100px"
                    src={logoBlue}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                  />
                )}
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Principal
            </Typography>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon sx={{ fontSize: 30 }} />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Detalles
            </Typography>
            <Item
              title="Métricas"
              to="/metrics"
              icon={<BarChartOutlinedIcon sx={{ fontSize: 30 }} />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Visualización
            </Typography>
            <Item
              title="Reportes"
              to="/reportes"
              icon={<Inventory2OutlinedIcon sx={{ fontSize: 30 }} />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="FAQ´s"
              to="/faq-section"
              icon={<HelpOutlineOutlinedIcon sx={{ fontSize: 30 }} />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* Lógica condicional para la sección de Acceso restringido */}
            {isAccessAllowed && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Acceso restringido
                </Typography>
                <Item
                  title="Configuración"
                  to="/settings"
                  icon={<SettingsOutlinedIcon sx={{ fontSize: 30 }} />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;