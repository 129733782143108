import React, { useEffect } from 'react';
import { useState } from 'react';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Route, Routes } from 'react-router-dom';

//Importación de providers
import { UserProvider } from "./components/providers/UserContext";
import { CRMProvider } from './components/providers/CRMProvider';
import { ClientProvider } from './components/providers/ClientProvider';
import { SettingsProvider } from './components/providers/SettingsProvider';

//Importacióon de componentes
import Topbar from './components/scenes/global/Topbar';
import Sidebar from "./components/scenes/global/SideBar";
import Dashboard from './components/dashboard/Dashboard';
import Login from "./components/auth/Login";
import MetricsContainer from './components/metrics/MetricsContainer';
import StepperContainer from './components/stepper/StepperContainer';
import Settings from './components/settingscomp/Settings';
import Faq from './components/faq/Faq';
import Reports from './components/report/Reports';

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  //Manejadores de login y logout
  const handleSuccessfulLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.clear()
    window.location.href = '/';
  };

  useEffect(() => {
    // Recuperar el estado de inicio de sesión desde el localStorage
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    // Guardar el estado de inicio de sesión en el localStorage: escucha logueo
    localStorage.setItem('isLoggedIn', isLoggedIn.toString());
  }, [isLoggedIn]);

  return (
    <UserProvider>
      <>
        {isLoggedIn ? (
          <>
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                  <Sidebar isSidebar={isSidebar} />
                  <main className="content">
                    <Topbar setIsSidebar={setIsSidebar}
                      handleLogout={handleLogout} />
                    <ClientProvider>
                      <Routes>
                        <Route exact path="/" element={<Dashboard />} />
                        <Route path="/metrics" element={
                          <CRMProvider>
                            <MetricsContainer />
                          </CRMProvider>} />
                        <Route path="/checkout" element={
                          <CRMProvider>
                            <SettingsProvider>
                              <StepperContainer />
                            </SettingsProvider>
                          </CRMProvider>} />
                        <Route path="/faq-section" element={<Faq />} />
                        <Route path="/settings" element={<CRMProvider><SettingsProvider><Settings /></SettingsProvider></CRMProvider>} />
                        <Route path="/reportes" element={<Reports />}></Route>
                      </Routes>
                    </ClientProvider>
                  </main>
                </div>
              </ThemeProvider>
            </ColorModeContext.Provider>
          </>) : (
          <>
            <CssBaseline />
            <Login onSuccessfulLogin={handleSuccessfulLogin} />
          </>
        )}
      </>
    </UserProvider>
  );
}

export default App;
