import React, { useContext } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { CRMContext } from '../providers/CRMProvider';
import { useTheme } from '@mui/material';
import { themeSettings } from '../../theme';


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
// ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend, Filler);

const LineChart = () => {

    const theme = useTheme(); //Contexto de tema de color
    // const colors = tokens(theme.palette.mode)

    const { variableCRM } = useContext(CRMContext);
    const scores = variableCRM?.content?.periodos ? Object.values(variableCRM.content.periodos) : [];
    const labels = variableCRM?.content?.periodos ? Object.keys(variableCRM.content.periodos) : [];
    const maxData = Math.max(...scores);
    const maxY = maxData * 2;
    const maxLimit = () => (maxY <= 0 ? null : maxY);
    
    console.log(scores)
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                align: 'center',
                fullWidth: true,
                labels: {
                    font: {
                        size: 18,
                        family: themeSettings(theme.palette.mode).typography.fontFamily,
                        weight: 'bold',
                    },
                    color: 'black'
                    // color: colors.grey[200],
                },
            },
        },
        
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 13,
                    },
                    color: 'black'
                    // color: colors.grey[200],
                },
            },
            y: {
                ticks: {
                    font: {
                        size: 13,
                    },
                    color: 'black',
                    // color: colors.grey[200],
                },
                // Establecer el valor máximo del eje Y
                max: maxLimit(),

            },
        },
    };

    const data = {
            datasets: [
                {
                    label: "Consultas por cada mes (últimos 120 días)",
                    data: scores,
                    tension: 0.3,
                    fill: true, // Relleno de área bajo la línea
                    backgroundColor: 'rgba(27, 2, 87, 0.9)', 
                    // borderColor: 'rgb(56, 102, 242)',
                    // borderWidth: 2,
                    pointRadius: 6,
                    pointBackgroundColor: 'white',
                    barThickness: 100,
                    hoverBackgroundColor: 'rgb(255, 99, 132)',
                },
            ],
            labels
        };

    return (
        <Bar data={data} options={options}/>
    )
};

export default LineChart