//CONTEXTO DE USUARIO PROVEEDOR DE LA APLICACIÓN: IMPLEMETACIÓN FIREBASE Y LOCAL DB

import React, { createContext, useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import users from './users';

const UserContext = createContext();

//Búsqueda de usuarios en USERS.js
function findUserByUID(uid) {
  return users.find((user) => user.UID === uid);
};

//Instancia user provider
function UserProvider({ children }) {

  //Variable de estado para guardar usuario
  const [usuario, setUsuario] = useState(null);

  //Montaje de componente para llamado de auth de Firebaso (maneja cambios de usuario, seteo de localStorage y busqueda de usuarios en local db)
  useEffect(() => {

    //Autenticación de usuarios y condicionales para manejar el logueo
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const usuarioEncontrado = findUserByUID(user.uid);
        setUsuario(usuarioEncontrado);
        //Guardado en STORAGE
        localStorage.setItem('authenticatedUserUID', user.uid);

      } else {
        setUsuario(null);
        //Remover usuario STORAGE
        localStorage.removeItem('authenticatedUserUID');

      }
    });

    // Limpieza de suscripción cuando el componente se desmonte
    return () => unsubscribe();

  }, []);


  // Escucha cambios en el localStorage
  useEffect(() => {
    const storedUID = localStorage.getItem('authenticatedUserUID');
    if (storedUID && usuario && storedUID !== usuario.UID) {
      const usuarioEncontrado = findUserByUID(storedUID);
      setUsuario(usuarioEncontrado);
    }
  }, [usuario]);



  return (
    <UserContext.Provider value={usuario}>
      {children}
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext };
