//COMPONENTE GENERADOR DE REPORTE

import React from 'react'
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
// import backgroundImage from '../../assets/images/Untitledbase.png'
import portada from '../../assets/images/portada.png';
import backgroundpag2 from '../../assets/images/backgroundpagina2.png';
import backgroundpag3 from '../../assets/images/backgroundpagina3.png';

//Importación de fuentes desde src
import PoppinsRegular from '../../assets/fonts/Poppins-Regular.ttf'
import PoppinsMedium from '../../assets/fonts/Poppins-Medium.ttf'

//Registro de fuentes
Font.register({
    family: 'Poppins',
    fonts: [
        { src: PoppinsRegular, fontStyle: 'normal', fontWeight: 'normal' }
    ]
})
Font.register({
    family: 'PoppinsMedium',
    fonts: [
        { src: PoppinsMedium }
    ]
})

// Definición de estilos para la generación del documento:
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica-Bold',
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    textContainer: {
        height: 130,
    },
    backgroundImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: -1,
    },
    content: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden', // Evita que el contenido se desplace a la siguiente página
    },
    title: {
        fontSize: 15,
        fontFamily: 'Helvetica-Bold',
        textAlign: 'center',
        color: 'white',
        marginTop: 70,
    },
    subtitleMes: {
        fontSize: 15,
        fontFamily: 'PoppinsMedium',
        textAlign: 'center',
        color: 'white',
        marginTop: 400,
        // marginLeft: 190
    },
    text: {
        fontSize: 9,
        fontFamily: 'Helvetica',
        textAlign: 'center',
        color: 'black',
        margin: 30,
    },
    textIntro: {
        fontSize: 9,
        fontFamily: 'Poppins',
        textAlign: 'left',
        color: 'black',
        marginTop: 100,
        marginLeft: 50,
        marginRight: 50,
        height: 50
    },
    textAccionesRealizadas: {
        fontSize: 9,
        fontFamily: 'Poppins',
        textAlign: 'left',
        color: 'black',
        marginTop: 280,
        marginLeft: 50,
        marginRight: 50
    },
    textAnalisisDeMercado: {
        fontSize: 9,
        fontFamily: 'Poppins',
        textAlign: 'left',
        color: 'black',
        marginTop: 300,
        marginLeft: 50,
        marginRight: 50
    },
    textAccionesAEjecutar: {
        fontSize: 9,
        fontFamily: 'Poppins',
        textAlign: 'left',
        color: 'black',
        marginTop: 75,
        marginLeft: 50,
        marginRight: 50
    },
    textFooter: {
        fontSize: 10,
        fontFamily: 'PoppinsMedium',
        textAlign: 'left',
        color: 'black',
        // marginTop: 80,
        marginLeft: 10,
        marginRight: 50
    },
    textSignature: {
        fontSize: 9,
        fontFamily: 'PoppinsMedium',
        textAlign: 'center',
        color: 'black',
        marginLeft: 10,
        marginRight: 50,
        // display: 'flex',
        // justifyContent: 'flex-end',
        // alignItems: 'flex-end',
        // height: '100%',
    },
    imageSignatureContainer: {
        maxHeight: '80%',
        position: 'relative',
        zIndex: -1,
    },
    imagenGrafico: {
        // width: '100%',
        marginTop: 110,
        marginLeft: 20,
        marginRight: 20

    },
    commentary: {
        margin: '20px',
        padding: 8,
        flexGrow: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.05)', // Color de fondo blanco con 5% de transparencia
        borderRadius: 5, // Opcional: agrega bordes redondeados

    },
    comment: {
        fontSize: 9,
        fontFamily: 'Helvetica',
        textAlign: 'center',
        color: 'black',
        margin: 4
    },
    textBold: {
        fontSize: 9,
        fontFamily: 'Helvetica-Bold',
        textAlign: 'center',
        color: 'black',
        margin: 10
    },
    coverPage: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    coverImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: -1,
    },
    containerPie: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'space-between',
        width: '100%',
        marginTop: 600,
    },
    containerA: {
        width: '60%',
    },
    containerB: {
        width: '40%',
        marginTop: 60,
        position: 'relative',
    },
});

const ReportPDF = ({ capturedCanvas, introduccionData, comment, selectedActionsDone, analisisMercadoData, selectedActionsToTake, customAction, clientsData, selectedClient, dateReport, usuarioAsesor, firma }) => {

    //Captura de imagen de gráficos: ternario para verificar existencia
    const imageDataURL = capturedCanvas ? capturedCanvas.toDataURL() : null;

    //Función para búsqueda de nombre de usuario por id
    function findClientName(clientsData, selectedClient) {
        let clientName = null;  // Inicializamos la variable clientName en null por si no se encuentra el cliente

        for (let i = 0; i < clientsData.length; i++) {
            if (clientsData[i].id === selectedClient) {
                clientName = clientsData[i].nombre;  // Asignación el nombre del cliente al encontrar el id
                break;  // Salida del bucle una vez que encontramos el cliente
            }
        }

        return clientName;
    };

    //Agregado de bullets e interlineado de items:
    const actionsDoneWithBullet = selectedActionsDone.map(action => `• ${action}`).join('\n');
    const actionsToTakeWithBullet = selectedActionsToTake.map(action => `• ${action}`).join('\n');
    const customActionWithBullet = `${customAction}`;
    const finalActionsToTake = `${actionsToTakeWithBullet}${customActionWithBullet.trim() !== '' ? `\n • ${customActionWithBullet}` : ''}`;


    //Variable nombre del cliente para incoporar en el reporte
    const clientName = findClientName(clientsData, selectedClient);


    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.content}>
                    <Image src={portada} style={styles.backgroundImage} />
                    <Text style={styles.subtitleMes}>{dateReport}</Text>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View style={styles.content}>
                    <Image src={backgroundpag2} style={styles.backgroundImage} />
                    <View style={styles.textContainer}>
                        <Text style={styles.textIntro}>Estimado/a {clientName}:{'\n'}{'\n'}{introduccionData?.mensaje}{'\n'}{'\n'}
                            {comment} </Text>
                    </View>
                    <View style={styles.textContainer}>
                        <Text style={styles.textAccionesRealizadas}>{actionsDoneWithBullet}</Text>
                    </View>
                    <View style={styles.textContainer}>
                        <Text style={styles.textAnalisisDeMercado}>{analisisMercadoData?.mensaje}</Text>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View style={styles.content}>
                    <Image src={backgroundpag3} style={styles.backgroundImage} />
                    {imageDataURL && <Image src={imageDataURL} style={styles.imagenGrafico} />}
                </View>
                <View style={styles.containerPie}>
                    <View style={styles.containerA}>
                        <Text style={styles.textAccionesAEjecutar}>{finalActionsToTake}</Text>
                        {/* <Text style={styles.textAccionesAEjecutar}>`• ${customAction}`</Text> */}
                    </View>
                    <View style={styles.containerB}>
                        <Text style={styles.textFooter}>PRÓXIMO REPORTE: dentro de los siguientes 45 días</Text>
                        <View style={styles.imageSignatureContainer}>
                            {firma && <Image style={{ maxWidth: '170', marginTop: '5', marginBottom: '5' }} src={firma}></Image>}
                        </View>
                        <Text style={styles.textSignature}>{usuarioAsesor}{'\n'}Asesor a cargo</Text>
                    </View>
                </View>
            </Page>
        </Document >
    );
};

export default ReportPDF