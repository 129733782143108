import React from 'react';
import MetricsSelect from './MetricsSelect'
import { Link } from 'react-router-dom';

//Importación hojas de estilos CSS
import './metrics.css'

//Importación de componentes MaterialUI
import { Box, Button, Divider, Grow } from '@mui/material';
import Container from '@mui/material/Container';
import { tokens } from '../../theme';
import { useTheme } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

//Importación componentes de gráficos
import PieChart from '../charts/PieChart';
import LineChart from '../charts/LineChart';
import EventsChart from '../charts/EventsChart';

// Importaciones USEREF y HTML2Canvas: captura de código y guardado en estado
import { useRef } from 'react';
import html2canvas from 'html2canvas';

//Importación de contexto para usar la variable de estado que aloja la captura de pantalla
import { useContext } from 'react';
import { CRMContext } from '../providers/CRMProvider';
import { useEffect } from 'react';

const MetricsContainer = () => {

  useEffect(() => {
    
    }, [])
  
  //Importación de tema de aplicación
  const theme = useTheme();
  tokens(theme.palette.mode);

  //Ref para referenciar el fragmento de html a carpturar + guardado en estado de la captura
  const captureRef = useRef(null);
  //La captura se guarda en la variable de estado que está en el CRMContext
  const {  setCapturedCanvas } = useContext(CRMContext);
  
  //Función de captura de gráficos
  const handleCapture = () => {
    const captureElement = captureRef.current;

    html2canvas(captureElement, { backgroundColor: null }).then((canvas) => {
      setCapturedCanvas(canvas);
      return
    });
  };

  return (
    <Grow in={true}>
      <div className='metrics-main-container'>
        <Container>
          <MetricsSelect />
        </Container>
        <Divider variant="middle" />
        <Box m="20px">
          <div className='title-metrics'>
            <h2>Métricas</h2>
            <h3>Estadísticas de propiedades CRM</h3>
          </div>
        </Box>
        <Divider variant="middle" />
        <div className='container-charts-canva' ref={captureRef}>
          <div className='chart-1-container'>
            <LineChart />
          </div>
          <Divider variant="middle" />
          <div className='group-chart-2'>
            <div className='chart-2-container'>
              <PieChart />
            </div>
            <div className='chart-3-container'>
              <EventsChart />
            </div>
          </div>
        </div>
        <Divider variant="middle" />
        <div className='send-button'>
          <Button variant="contained" size="large" color="secondary" endIcon={<SendIcon />} onClick={handleCapture} component={Link} to="/checkout">
            GENERAR REPORTE
          </Button>
        </div>
        <Divider variant="middle" />
      </div>
    </Grow>
  );
};

export default MetricsContainer