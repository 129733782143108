import * as React from 'react';
import { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { IconButton, TableFooter, TablePagination, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';

const Reports = () => {
    const [reportData, setReportData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterAsesor, setFilterAsesor] = useState('');
    const [asesores, setAsesores] = useState([]);

    // Obtener datos de Firestore con la configuración clásica
    const db = getFirestore();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'dbreportes'));
                const uniqueAsesores = Array.from(
                    new Set(querySnapshot.docs.map(doc => doc.data().asesor))
                );
                setAsesores(['', ...uniqueAsesores]);

                const sortedData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
                    .sort((a, b) => b.fecha.toMillis() - a.fecha.toMillis());

                setReportData(sortedData);
            } catch (error) {
                console.error('Error al obtener datos de Firestore:', error);
            }
        };

        fetchData();
    }, [db]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div style={{ margin: '3%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                    <h2>Tabla de contenidos de reportes</h2>
                    <p>Visualización de la base de datos de reportes generados</p>
                </div>

                <div>
                    <FormControl sx={{ m: 3, minWidth: 80 }}>
                        <InputLabel htmlFor="filter-asesor" color="secondary" sx={{ fontSize: 16 }}>Asesor:</InputLabel>
                        <Select
                            labelId="filter-asesor-label"
                            id="filter-asesor"
                            value={filterAsesor}
                            onChange={(e) => setFilterAsesor(e.target.value)}
                            label="Filtrar por Asesor"
                            sx={{ minWidth: 400 }}
                            variant='outlined'
                        >
                            <MenuItem value="">
                                <em>Todos</em>
                            </MenuItem>
                            {asesores.map((asesor) => (
                                <MenuItem key={asesor} value={asesor}>
                                    {asesor}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

            </div>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ASESOR</TableCell>
                            <TableCell align="left">CLIENTE</TableCell>
                            <TableCell align="left">FECHA DE GENERACIÓN</TableCell>
                            <TableCell align="left">PROPIEDAD</TableCell>
                            <TableCell align="left">VISTA PREVIA</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData
                            .filter((row) => !filterAsesor || row.asesor === filterAsesor)
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.asesor}
                                    </TableCell>
                                    <TableCell align="left">{row.cliente}</TableCell>
                                    <TableCell align="left">{row.fecha.toDate().toLocaleString()}</TableCell>
                                    <TableCell align="left">{row.propiedad}</TableCell>
                                    <TableCell align="center">
                                        <a href={row.reporte} target="_blank" rel="noopener noreferrer">
                                            <IconButton
                                                aria-label="Descargar"
                                            >
                                                <PreviewOutlinedIcon fontSize='large' />
                                            </IconButton>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={5}
                                count={reportData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Reports;
