// Array de usuarios con sus datos

//IMPORTACIONES DE FIRMAS
import comparato from "../../assets/images/comparato.png";
import angulo from "../../assets/images/angulo.png";
import antun from "../../assets/images/antun.png";
import carranza from "../../assets/images/carranza.png";
import centeno from "../../assets/images/centeno.png";
import spongia from "../../assets/images/spongia.png";
import parodi from "../../assets/images/parodi.png";
import pedro from "../../assets/images/pedro.png";
import justo from "../../assets/images/justo.png"

//Array de usuarios, credenciales y firmas (enviar en env los UID)
const users = [
  { nombre: "Marcos Marchi", codigo: 15, UID: "uid2", avatar: "MM" },
  {
    nombre: "Franco Marchi",
    codigo: 2,
    UID: "D2fqVOEZjvPhi1pOox3oqH0CsSV2",
    avatar: "FM",
    firma: antun,
  },
  { nombre: "Pedro Moratorio", codigo: 3, UID: "uid3", avatar: "PM" },
  { nombre: "Carla Echenique", codigo: 4, UID: "uid4", avatar: "CE" },
  { nombre: "Pedro Moratorio", codigo: 5, UID: "uid5", avatar: "PM" },
  { nombre: "No asignado", codigo: 0, UID: "uid0", avatar: "NA" },
  { nombre: "William Ordonez", codigo: 6, UID: "uid6", avatar: "WO" },
  { nombre: "María Lidia Muller", codigo: 7, UID: "uid7", avatar: "MLM" },
  { nombre: "Lucas Campana", codigo: 8, UID: "uid8", avatar: "LC" },
  { nombre: "Lourdes Corzo", codigo: 9, UID: "uid9", avatar: "LC" },
  {
    nombre: "Natalia Imola",
    codigo: 21,
    UID: "5rVOEvOiG8bKaEfbLL9BE2w53FE3",
    avatar: "NI",
    firma: antun,
  },//Clientes de Antún que ahora son de Natalia Imola
  { nombre: "Ignacio Osorio", codigo: 11, UID: "uid11", avatar: "IO" },
  { nombre: "Rosario Torres", codigo: 12, UID: "uid12", avatar: "RT" },
  { nombre: "Ramiro Diaz", codigo: 13, UID: "uid13", avatar: "RD" },
  { nombre: "Virginia Osorio", codigo: 14, UID: "GTcGheEijFfzHHrAXmE057nnyJ72", avatar: "VO" },
  {
    nombre: "Maximiliano Angulo",
    codigo: 15,
    UID: "p4Sf3pVZs8VirdZ0ZHJI4jISwxv1",
    avatar: "MA",
    firma: angulo,
  },
  { nombre: "Santiago Novillo", codigo: 16, UID: "uid16", avatar: "SN" },
  {
    nombre: "Candelaria Comparato",
    codigo: 17,
    UID: "lM6sDXggaqNfUX4BcWVb4s1tFcC3",
    avatar: "CC",
    firma: comparato,
  },
  {
    nombre: "Ulises Spongia",
    codigo: 18,
    UID: "N5guNfT3weQ5azrDXcwqn0WZLvw1",
    avatar: "US",
    firma: spongia,
  },
  { nombre: "Usuario Tester", codigo: 2, UID: "uid", avatar: "UT" },
  {
    nombre: "Daniel Carranza",
    codigo: 19,
    UID: "fql6UlltqnOy8FJHNag7zwo3P6H3",
    avatar: "DC",
    firma: carranza,
  },
  {
    nombre: "Dionisio Centeno",
    codigo: 20,
    UID: "uIhuy0lCRjU3Yft8dEfBANWSsBL2",
    avatar: "DC",
    firma: centeno,
  },
  {
    nombre: "Hipatia Parodi",
    codigo: 22,
    UID: "C9kFRvxxwqdaHFnzT9lqk3Mr6CJ3",
    avatar: "HP",
    firma: parodi,
  },
  {
    nombre: "Pedro Filiberti",
    codigo: 26,
    UID: "gdC7pvAnKNQQByerWNaeV4KsrRi2",
    avatar: "PF",
    firma: pedro,
  },
  {
    nombre: "Justo Ferrer",
    codigo: 25,
    UID: "KgWd0NAwNvdMjPgzo7oRR0wEZtF3",
    avatar: "HP",
    firma: justo,
  },
];

// Exportar el array para que pueda ser utilizado en otros archivos
export default users;
