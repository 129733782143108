import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs from 'dayjs'; // Importar dayjs
import './metrics.css';
import { useContext } from 'react';
import { CRMContext } from '../providers/CRMProvider';
import { useState } from 'react';
import { useEffect } from 'react';
// import { useState } from 'react';

const MetricsSelect = () => {

  //Importación variables de estado de contexto CRMProvider
  const { clientsData, selectedClient, setSelectedClient, propertiesData, setSelectedProperty, setFechaDesdeFormateada, setFechaHastaFormateada, setFechaActual, setFecha120dias } = useContext(CRMContext);

  //Recorrido de array con map para guardados de nombres en nuevo array "propietarios"
  const propietarios = clientsData.map(ob => ({ id: ob.id, nombre: ob.nombre }))
  const propiedades = propertiesData.map(ob => ({ id: ob.id, titulo: ob.titulo }));

  //Variables para guardar fechas desde el selector
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  
  useEffect(() => {
    setFechaDesdeFormateada(formatCustomDate(fechaDesde));
    setFechaHastaFormateada(formatCustomDate(fechaHasta));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaDesde, fechaHasta]);


  const handlePropertyChange = (event, value) => {
    setSelectedProperty(value ? value.id : null);

    if (value) {
      // Calcular la fecha actual
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque enero es 0
      const yyyy = today.getFullYear();
      const fechaActual = `${dd}/${mm}/${yyyy}`;
      setFechaActual(fechaActual);

      // Calcular la fecha 120 días atrás
      const fecha120 = new Date(today);
      fecha120.setDate(fecha120.getDate() - 120);
      // const dd120 = String(fecha120.getDate()).padStart(2, '0');
      const mm120 = String(fecha120.getMonth() + 1).padStart(2, '0');
      const yyyy120 = fecha120.getFullYear();

      const fecha120dias = `${1}/${mm120}/${yyyy120}`;
      setFecha120dias(fecha120dias);
      console.log(fecha120dias)
    }
  };

  // Función para compatibilizar formato de fecha
  const formatCustomDate = (dateObj) => {
    if (dateObj && dateObj.$d) {
      const date = new Date(dateObj.$d);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return '';
  };

  return (
    <div className='metricsSelectContainer'> 
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size='medium'
        options={propietarios}
        getOptionLabel={(option) => `${option.id} - ${option.nombre}`}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Cliente" />}
        onChange={(event, value) => setSelectedClient(value ? value.id : null)}
        //Arreglo de warning provisorio. Ver
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      <Autocomplete
        disablePortal
        id="combo-box-demo-2"
        size="medium"
        options={propiedades} // Reemplaza con el array de propiedades correspondiente
        getOptionLabel={(option) => `${option.id} - ${option.titulo}`}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Propiedad" />}
        // value={selectedProperty}
        onChange={handlePropertyChange}
        //Arreglo de warning provisorio. Ver
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={!selectedClient}
      />
      {/* Autocompletado para la fecha "desde" */}
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DatePicker
          label="Fecha desde"
          value={fechaDesde}
          onChange={(date) => setFechaDesde(date)}
          textField={(params) => <TextField {...params} />}
          inputFormat="dd/MM/yyyy"
        />
        <DatePicker
          label="Fecha Hasta"
          value={fechaHasta}
          disableFuture={true}
          onChange={(date) => setFechaHasta(date)}
          textField={(params) => <TextField {...params} />}
          inputFormat="dd/MM/yyyy"
        />
      </LocalizationProvider>
    </div>

  );
};

export default MetricsSelect;