import React from 'react';
import { useContext } from "react";

// Contextos de color y usuario activo
import { ColorModeContext, tokens } from "../../../theme";
import { UserContext } from "../../providers/UserContext";

//Importaciones MaterialUI
import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

const Topbar = ({ handleLogout }) => {

  //Variables de contextos: tema y usuario
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const user = useContext(UserContext)

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* Sección de avatar de usuario */}
      <Box
        display="flex"
        align="center"
      >
        <Avatar>{user?.avatar}</Avatar>
        <Typography variant="h5" margin={1} color={colors.grey[100]}>{user?.nombre}</Typography>
      </Box>

      {/* Íconos shutdown y theme */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon/>
          ) : (
            <LightModeOutlinedIcon/>
          )}
        </IconButton >
        {/* Manejador fin de sesión */}
        <IconButton onClick={handleLogout}>
          <ExitToApp/>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;