
import React, { createContext, useState, useEffect, useContext } from 'react';
import { UserContext } from './UserContext';
// import { findRenderedDOMComponentWithTag } from 'react-dom/test-utils';

// Creamos un contexto para almacenar los datos de la API
const CRMContext = createContext();

const CRMProvider = ({ children }) => {
  //Variables de estado:
  const [clientsData, setClientsData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [propertiesData, setPropertiesData] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [variableCRM, setVariableCRM] = useState()
  const [variableCRMMensual, setVariableCRMMensual] = useState()
  
  //Variables para guardar fechas formateadas desde DatePicker a un formato compatible con la consulta al server
  const [fechaDesdeFormateada, setFechaDesdeFormateada] = useState(null);
  const [fechaHastaFormateada, setFechaHastaFormateada] = useState(null);

  //Variables que contienen las fechas formateadas para la lógica de los últimos 4 meses.
  const [fechaActual, setFechaActual] = useState(null)
  const [fecha120dias, setFecha120dias] = useState(null)

  //Guardado en variable de captura de gráficos renderizados en pantalla (para usar en el reporte)
  const [capturedCanvas, setCapturedCanvas] = useState(null);

  //Hook para crear contexto
  const user = useContext(UserContext);

  //Fetch para clientes desde la API
  useEffect(() => {
    if (user?.codigo) {
      //Fecha actual
      const fecha = new Date();
      // Obtener día, mes año y pasar a una variable
      const dia = fecha.getDate();
      const mes = fecha.getMonth() + 1;
      const anio = fecha.getUTCFullYear();
      const fechaFormateada = `${dia}/${mes}/${anio}`
      const fetchData = async () => {
        try {
          const params = new URLSearchParams({
            token: process.env.REACT_APP_API_TOKEN,
            idcliente: process.env.REACT_APP_API_ID,
            fecha_desde: "01/01/2012",
            fecha_hasta: fechaFormateada,
            usuario: user?.codigo,
            carpeta: 3,
          });

          const response = await fetch(`${process.env.REACT_APP_API_URL}?${params}`);
          const jsonData = await response.json();
          setClientsData(jsonData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }

  }, [user]);

  // Fetch para propiedades relacionadas con el cliente seleccionado
  useEffect(() => {
    const fetchPropertiesData = async () => {
      //Condicional para diferenciar si hay cliente
      if (selectedClient) {
        try {
          const params = new URLSearchParams({
            id: process.env.REACT_APP_CRM_ID,
            token: process.env.REACT_APP_CRM_TOKEN,
            idpropietario: selectedClient,
          });
          const response = await fetch(`${process.env.REACT_APP_CRM_URI}?${params}`);
          const jsonData = await response.json();
          setPropertiesData(jsonData);
        } catch (error) {
          console.error('Error fetching properties data:', error);
        }
      }
    };
    fetchPropertiesData();
  }, [selectedClient]);

  //Fetch para estadísticas de propiedades ultimos 4 meses
  useEffect(() => {
    //Condicionales para realizar la llamada, siempre y cuando se seleccionen los valores:
    if (selectedProperty && fechaActual && fecha120dias) {
      const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_URL_STATISTICS;
        const params = new URLSearchParams();
        params.append('idcliente', '533');
        params.append('token', 'd41d8cd98f00b204e9800998ecf8427e');
        params.append('fecha_desde', fecha120dias);
        params.append('fecha_hasta', fechaActual);
        params.append('idInmueble', selectedProperty);

        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            body: params,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const responseData = await response.json();
          setVariableCRM(responseData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();

      console.log(variableCRM)
    };
  }, [selectedProperty, fechaActual, fecha120dias]);

  //Fetch para estadísticas de propiedades Mensual
  useEffect(() => {
    if (selectedProperty && fechaDesdeFormateada && fechaHastaFormateada) {
      const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_URL_STATISTICS;
        const params = new URLSearchParams();
        params.append('idcliente', '533');
        params.append('token', 'd41d8cd98f00b204e9800998ecf8427e');
        params.append('fecha_desde', fechaDesdeFormateada);
        params.append('fecha_hasta', fechaHastaFormateada);
        params.append('idInmueble', selectedProperty);

        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            body: params,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const responseData = await response.json();
          setVariableCRMMensual(responseData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }

  }, [selectedProperty, fechaDesdeFormateada, fechaHastaFormateada]);

  // Proporcionamos el contexto con los datos a los componentes hijos
  return (
    <CRMContext.Provider value={{ clientsData, selectedClient, setSelectedClient, propertiesData, selectedProperty, setSelectedProperty, variableCRM, variableCRMMensual, capturedCanvas, setCapturedCanvas, setFechaDesdeFormateada, setFechaHastaFormateada, setFechaActual, setFecha120dias }}>
      {children}
    </CRMContext.Provider>
  );
};

export { CRMProvider, CRMContext };
