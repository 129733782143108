import React, { createContext, useContext, useEffect, useState } from 'react'
import { getFirestore, doc, getDoc, setDoc, addDoc, collection } from 'firebase/firestore/lite';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { CRMContext } from './CRMProvider';

// Creación del contexto SETTINGSPROVIDER: provee datos desde firestore, storage y bitly para el funcionamiento global de los componentes de la app, principalmente en configuración.
const SettingsContext = createContext();

// Obtenemos una instancia del firestore desde el app inicializado en index.js
const SettingsProvider = ({ children }) => {

  //Variables para las acciones pasadas y a tomar seleccionadas que van al reporte
  const [selectedActionsDone, setSelectedActionsDone] = useState([]);
  const [selectedActionsToTake, setSelectedActionsToTake] = useState([]);

  //Token bitly para acceso
  const apiKey = process.env.REACT_APP_BITLY_TOKEN;

  // Variables que guardan mensajes de bienvenida, análisis de mercado y wpp (se configuran desde settings y se utilizan en report y toda la app donde sea requerido)
  const [introduccionData, setIntroduccionData] = useState({});
  const [analisisMercadoData, setAnalisisMercadoData] = useState({});
  const [whatsAppMessage, setWhatsAppMessage] = useState({})

  //Variable de estado para la fecha de generación del reporte
  const [dateReport, setDateReport] = useState('');

  //Variables traídas desde CRM context con datos de propiedades y propiedad seleccionada para proveerlas a las funciones
  const { propertiesData, selectedProperty } = useContext(CRMContext)

  //Variables de estado para mensajes predefinidos:
  const [actionsDone, setActionsDone] = useState(["Se realizaron visitas con colegas.",
    "Se cruzó la propiedad con nuestra base de datos.",
    "Se publicó en el estado de WhatsApp.",
    "Se retomaron antiguos leads.",
    "Se relanzó la propiedad en portales con nueva portada / nuevo título."])

  const [actionsToTake, setActionsToTake] = useState(["Re-analizar el precio.",
    "Trabajar en exclusividad con Prisma.",
    "Colocar cartel de venta. ",
    "Cruzar la propiedad con nuestra base de datos. ",
    "Recordar sobre la propiedad a colegas de la zona.",
    "Publicar en estado de WhatsApp.",
    "Retomar antiguos leads.",
    "Organizar un Open house para muestra masiva.",
    "Relanzar la propiedad en portales con nueva portada / nuevo título.",
    "Publicar la propiedad en redes sociales personales."])

  //Función para formatear la fecha de reporte sobre la fecha actual (Dentro de useEffect. Se vuelve a montar el componente solo si cambia la variable dateReport)
  useEffect(() => {
    //Obtención de fecha actual
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);

    //Arreglo para re-denominar fechas
    const monthNames = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    //FORMATEO DE FECHAS PARA OBTENER MES Y AÑO MENOS 1
    const formattedMonth = `${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`;

    setDateReport(formattedMonth);
    return

  }, [dateReport]);

  // Instancia de Firestore DB
  const db = getFirestore();

  //Funciones para obtener datos para la actualización de los mensajes estáticos de analisis de mercado y saludo inicial
  useEffect(() => {
    const obtenerDatos = async () => {
      try {
        // Acceso al documento "introducción"
        const introduccionDocRef = doc(db, 'mensajes', 'introducción');
        const introduccionSnapshot = await getDoc(introduccionDocRef);

        if (introduccionSnapshot.exists()) {
          setIntroduccionData(introduccionSnapshot.data());
        }
        // Acceso al documento "analisisMercado"
        const analisisMercadoDocRef = doc(db, 'mensajes', 'analisisMercado');
        const analisisMercadoSnapshot = await getDoc(analisisMercadoDocRef);

        if (analisisMercadoSnapshot.exists()) {
          setAnalisisMercadoData(analisisMercadoSnapshot.data());
        }
        // Acceso al documento whatsapp
        const whatsAppMessageDocRef = doc(db, 'mensajes', 'whatsappmessage');
        const whatsAppMessageSnapshot = await getDoc(whatsAppMessageDocRef);

        if (whatsAppMessageSnapshot.exists()) {
          setWhatsAppMessage(whatsAppMessageSnapshot.data());
        }
      } catch (error) {
        console.error("Error al obtener los documentos:", error);
      }
    };

    obtenerDatos();
  }, []);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 

  // Función para actualizar o resubir los datos de mensaje de saludo en FS
  const actualizarIntroduccionData = async (nuevosDatos) => {
    try {
      const introduccionDocRef = doc(db, 'mensajes', 'introducción');
      await setDoc(introduccionDocRef, nuevosDatos, { merge: true });
      setIntroduccionData(nuevosDatos);
    } catch (error) {
      console.error("Error al actualizar el documento de introducción:", error);
    }
  };

  // Función para actualizar los datos de análisis de mercado
  const actualizarAnalisisMercado = async (nuevosDatos) => {
    try {
      const analisisMercadoDocRef = doc(db, 'mensajes', 'analisisMercado');
      await setDoc(analisisMercadoDocRef, nuevosDatos, { merge: true });
      setAnalisisMercadoData(nuevosDatos);
    } catch (error) {
      console.error("Error al actualizar el documento de introducción:", error);
    }
  };

  // Función para actualizar los datos de whatstapp message
  const actualizarWhatsAppMessage = async (nuevosDatos) => {
    try {
      const whatsAppMessageDocRef = doc(db, 'mensajes', 'whatsappmessage');
      await setDoc(whatsAppMessageDocRef, nuevosDatos, { merge: true });
      setWhatsAppMessage(nuevosDatos);
    } catch (error) {
      console.error("Error al actualizar el documento de introducción:", error);
    }
  };


  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 

  //Función que realiza el envío de los documentos de reportes vía WAP
  const enviarLinkFormularioWhatsApp = async (dataClient, url, wpMessage, telefono) => {
    try {
      //Acortamiento de enlace de Firestore: try para probar el acortamiento en caso de que la api bitly esté disponible. Entra al if si la respuesta de la api es favorable.
      const response = await fetch('https://api-ssl.bitly.com/v4/shorten', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({ long_url: url }),
      });

      if (response.ok) {
        const data = await response.json();
        window.open(
          `https://api.whatsapp.com/send?phone=${telefono}&text=${encodeURIComponent(
            `Hola ${dataClient.nombre} 👋! ${wpMessage.mensaje} 🙌 ${data.id} ¡Gracias por elegirnos!`
          )}`
        );
        return

      } else {
        //De no obtener respuesta favorable de bitly, y para completar el envío, utiliza la versión larga del enlace
        window.open(
          `https://api.whatsapp.com/send?phone=${telefono}&text=${encodeURIComponent(
            `Hola ${dataClient.nombre} 👋! ${wpMessage.mensaje} 🙌 ${url} ¡Gracias por elegirnos!`
          )}`
        );
        console.error('Error al acortar el enlace');
        return

      }
    } catch (error) {
      console.error('Error de red:', error);
    }

  };

  //Función para subir archivo de reporte a Storage y obtener enlace para enviar. Lo hace de manera simultánea.
  const executeFunction = async (usuarioAsesor, clientsData, selectedClient, pdfDocument) => {

    // 1. Verifica si todas las variables son válidas para poder realizar el envío
    if (usuarioAsesor && clientsData && selectedClient && pdfDocument) {
      const dataClient = clientsData.find(client => client.id === selectedClient);

      //Función para normalizar números de teléfono: 
      const telefono = (dataClient.cel).replace(/[^0-9+]/g, '')

      try {
        // 2. Convertir el documento PDF en un blob para realizar la subida
        const pdfBlob = new Blob([await pdfDocument], { type: 'application/pdf' });

        // 3. Guardar el blob en Firebase Storage y obtener la URL
        const storage = getStorage();
        const pdfRef = ref(storage, `reportes/${dateReport.toString()}-${dataClient.nombre}`); // Cambia el nombre y la ubicación según tus necesidades

        await uploadBytes(pdfRef, pdfBlob);

        // Obtener la URL del PDF almacenado en Firebase Storage
        const pdfUrl = await getDownloadURL(pdfRef);
        console.log('PDF guardado en Storage y URL obtenida:', pdfUrl);

        if (pdfUrl) {
          enviarLinkFormularioWhatsApp(dataClient, pdfUrl, whatsAppMessage, telefono);
          await uploadDocument(usuarioAsesor, dataClient, pdfUrl)
          return;

        } else {
          console.error('No se pudo recibir el enlace');
        }

      } catch (error) {
        console.error('Error al guardar el PDF en Storage y obtener la URL:', error);
      }
    } else {
      console.error('Algunas de las variables pasadas no son válidas.');
    }
  };


  useEffect(() => {
    // Observa los cambios en selectedActionsDone y formatea el arreglo
    setSelectedActionsDone(selectedActionsDone);
  }, []);

  useEffect(() => {
    // Observa los cambios en selectedActionsToTake y formatea el arreglo
    setSelectedActionsToTake(selectedActionsToTake);
  }, []);

//Función para subir el reporte a Database de reportes en FB
  const uploadDocument = async (usuarioAsesor, dataClient, pdfUrl) => {
    try {
      //Instancia de acceso a FIRESTORE
      const db = getFirestore();

      // Busca en el listado de propiedades la que coincide con el id de selectedProperty y guarda el objeto completo
      const propiedadSeleccionada = propertiesData?.find(property => property.id === selectedProperty && selectedProperty);

      // If para condicionar que suba si está la propiedad seleccionada
      if (propiedadSeleccionada) {
        // Referencia la colección 'dbreportes' y añadir un nuevo documento con addDoc
        const dbreportesRef = collection(db, 'dbreportes');

        // Datos a agregar al documento (armado del documento con sus propiedades)
        const reporteData = {
          asesor: usuarioAsesor,
          cliente: dataClient.nombre,
          propiedad: propiedadSeleccionada.titulo,
          fecha: new Date(),
          reporte: pdfUrl
        };

        // Sube el dpcumento a la colección
        const nuevoReporte = await addDoc(dbreportesRef, reporteData);

        console.log('Nuevo reporte añadido a dbreportes con ID:', nuevoReporte.id);
      } else {
        console.error('No se encontró la propiedad seleccionada en propertiesData.');
      }
    } catch (error) {
      console.error('Error al crear y guardar el reporte en dbreportes:', error);
    }
  };

  // Proporcionamos el contexto con los datos a los componentes hijos
  return (
    <SettingsContext.Provider value={{ selectedActionsDone, selectedActionsToTake, setSelectedActionsDone, setSelectedActionsToTake, actionsDone, actionsToTake, dateReport, setDateReport, introduccionData, setIntroduccionData, analisisMercadoData, setAnalisisMercadoData, actualizarIntroduccionData, actualizarAnalisisMercado, whatsAppMessage, setWhatsAppMessage, actualizarWhatsAppMessage, executeFunction }}>
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, SettingsContext };